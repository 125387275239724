// WelcomePage.js
import React from 'react';

const WelcomePage = () => {
  return (
    <div className="container">
      <h1>Welcome to Hoopsbot!</h1>
      <p>Thank you for your donation. You're awesome!</p>
      // Add more copy here as needed.
    </div>
  );
};

export default WelcomePage;
