import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'; 

function About() {
  return (
    <div>
      <Helmet>
        <title>About - Hoopsbot Blog 🏀🤖</title>
        <meta name="description" content="Learn more about Hoopsbot and our mission to provide the best basketball content." />
      </Helmet>

      <h2>About Hoopsbot</h2>
      <section id="about-us">
        <h1>About Us</h1>
        <p>
            Basketball is not just a game; it's a complex dance of strategy, metrics, and raw passion. At <strong>HoopsBot</strong>, we recognize the challenges enthusiasts like ChalkTalk Charlie face in breaking down plays or finding in-depth analysis beyond the basic stats. We also understand the dilemmas of GameDay Gary, who, amid his busy weekends, seeks reliable data to make that smart bet.
        </p>
        <p>
            For those fans and subscribers who've felt overwhelmed by the vastness and complexity of basketball metrics, we've simplified the equations. We delve into player performances, teamwork intricacies, and the ripple effects of injuries, bringing you nuanced insights that are easy to digest.
        </p>
        <p>
            But we're more than just stats and data. We are a thriving community. If you've ever felt that other platforms were too generic or too advanced for your taste, you'll find solace here. Our platform is tailored for both the casual fan who just wants to keep up-to-date and the subscriber eager to understand the deeper intricacies of the game.
        </p>
        <p>
            Access to games shouldn't be a luxury. At HoopsBot, we aim to bridge the gap, ensuring every fan and subscriber has the opportunity to experience the thrill of the game, regardless of broadcast restrictions or pricey subscriptions.
        </p>
        <p>
            Our commitment isn't to fleeting trends or financial gain but to you, our dedicated community. The heartbeat of <strong>HoopsBot</strong> is fueled by shared love, passion, and an insatiable curiosity for basketball.
        </p>
        <p>
            We're not just another basketball platform; we're a family. Together, we're redefining how the world experiences basketball.
        </p>
        <p><em>— The 🤖 Hoopsbot</em></p>
    </section>

    <section id="contact">
        <p>For Customer Service inquiries, please <a href="mailto:hi@hoopsbot.com">reach out to us</a>.</p>
        <p>If you're interested in Advertising opportunities, <a href="mailto:ads@hoopsbot.com">contact us</a>. We promise engaging content and advertising options that cater to diverse preferences, ensuring a mutually beneficial and lasting partnership.</p>
    </section>



      <p>
      <Link to="/checkout" className="nav-link">Checkout</Link>
      <Link to="/subscription" className="nav-link">Subscribe</Link>
      </p>
      {/* You can add more content or sections as needed */}
    </div>

    
  );
}

export default About;
