import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import netlifyIdentity from 'netlify-identity-widget';

const cardStyle = {
  style: {
    base: {
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
  },
};

function SubscriptionForm() {
  const [subscribing, setSubscribing] = useState(false);
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  // can remove this code block. just for your reference
  useEffect(() => {
    // reload to see updated user object
    console.log(netlifyIdentity.currentUser())
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Handling submit...');

    if (!stripe || !elements) {
      console.warn('Stripe or elements is not initialized.');
      return;
    }

    setSubscribing(true)
    const cardNumberElement = elements.getElement(CardNumberElement);
    console.log('Card number element:', cardNumberElement);

    // Create a payment method
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    if (error) {
      console.error('Failed to create payment method:', error);
      setSubscribing(false)
      return;
    }

    // Fetch the subscription client secret from a Netlify function
    fetch('/.netlify/functions/create-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, paymentMethodId: paymentMethod.id }),
    })
      .then(response => response.json())
      .then(async data => {
        const { subscription, error } = data;

        if (error) {
          console.error(error);
        } else {
          console.log('[Subscription]', subscription);

          const { url, token } = netlifyIdentity.currentUser();

          // Updating user metadata with paid status
          const res = await fetch(`${url}/user`, {
            method: 'PUT',
            headers: {
              Authorization: "Bearer " + token.access_token
            },
            body: JSON.stringify({
              data: {
                paid: true
              }
            })
          })

          if (res.ok) {
            const data = await res.json()

            /**
             * I don't know if it is best way to udpate the currentUser object.
             * but if there is no built-in function to get a updated currentUser object in netlify, it would be best solution. 
             * 
             * but it willl require a reload or proper use of a state management if you don't want a reload to use updateduser information in in this page.
             */
            const currentUser = JSON.parse(localStorage.getItem("gotrue.user"));
            localStorage.setItem("gotrue.user", JSON.stringify({
              ...currentUser,
              ...data
            }));
          }
          navigate('/welcome-subscriber');
        }

        setSubscribing(false)
      })
  };

  return (
    <div className="container">
      <h1 className="text-center mt-5">Subscribe to Hoopsbot</h1>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
          />
        </div>
        <div className="form-group">
          <label>Card Number</label>
          <CardNumberElement options={cardStyle} className="form-control" />
        </div>
        <div className="form-group">
          <label>Expiration Date</label>
          <CardExpiryElement options={cardStyle} className="form-control" />
        </div>
        <div className="form-group">
          <label>CVC</label>
          <CardCvcElement options={cardStyle} className="form-control" />
        </div>
        <button type="submit" className="btn btn-primary" disabled={!stripe}>
          {subscribing ? 'Subscribing...' : 'Subscribe'}
        </button>
      </form>
    </div>
  );
}

export default SubscriptionForm;
