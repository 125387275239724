import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const ColorPage = () => {
  const location = useLocation();
  const { name } = useParams();
  const item = location.state?.item;

  return (
    <div>
      <h1>{name}'s Favorite Color</h1>
      <h1>Test</h1>
      <p>{item ? item.favorite_color : 'Color not found'}</p>
    </div>
  );
};

export default ColorPage;
