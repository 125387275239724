import React, { useState, useEffect } from 'react';
import client from './Contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from 'react-helmet';
import netlifyIdentity from 'netlify-identity-widget';
import DatabasePage from './DatabasePage'; // Import the new page
import 'font-awesome/css/font-awesome.min.css';
import ReactGA4 from 'react-ga4';
import CountdownTimer from './CountdownTimer';
import About from './About'; 
import Calendar from './Calendar'; 
import Contact from './Contact';  
import PostDetails from './PostDetails';
import CheckoutForm from './CheckoutForm'; 
import SubscriptionForm from './SubscriptionForm'; 
import WNBA from './WNBA';
import NBA from './NBA';
import NCAA from './NCAA';
import BasketballAnalytics from './BasketballAnalytics';
import OddsPage from './OddsPage';
import NBAOdds from './NBAOdds';
import AuthorPosts from './AuthorPosts';
import WelcomePage from './WelcomePage';
import WelcomeSubscriber from './WelcomeSubscriber.js';
import Identity from './Identity';
import KUBasketballSchedule from './KUBasketballSchedule';
import ColorPage from './ColorPage'; // A new page you will create
import Teams from './pages/Teams.js';




// Importing the required functions from helpers.js
import { formatDate, options, truncateWords, richTextToPlainText } from './helpers';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE || "");

function MainContent() {
  const [posts, setPosts] = useState([]);
  const [user, setUser] = useState(null); // <-- New state for user
  const location = useLocation();
  
  function handleLoginSignup() {
    window.netlifyIdentity.open();
  }
  useEffect(() => {
    // Initialize Netlify Identity
    netlifyIdentity.init();
  
    const handleLogin = (user) => {
      console.log("User logged in", user); // Debugging line
      console.log("User metadata:", user.user_metadata);
      setUser(user);
    };
  
    const handleLogout = () => {
      console.log("User logged out"); // Debugging line
      setUser(null);
    };
  
    // Listen for login and logout events
    netlifyIdentity.on('login', handleLogin);
    netlifyIdentity.on('logout', handleLogout);
  
    // Fetch blog posts
    client.getEntries({
      content_type: 'blog',
      order: '-fields.published',
      include: 2  // fetch linked entries up to 2 levels deep
    })
    .then(response => {
      setPosts(response.items);
    })
    .catch(console.error);
  
    // Cleanup
    return () => {
      netlifyIdentity.off('login', handleLogin);
      netlifyIdentity.off('logout', handleLogout);
    };
  }, []);

  useEffect(() => {
    ReactGA4.initialize('G-80L7YYXV7R');
    ReactGA4.send('pageview');
  }, [location]);


  return (
    <div className="container">
      <Helmet>
        <title>All things Basketball Analytics ~ Hoopsbot</title>
        <meta name="description" content="Dive deep into the world of basketball analytics with Hoopsbot." />
      </Helmet>

      {/* <header className="d-flex justify-content-between align-items-center my-4">
        <Link to="/" className="text-decoration-none">
            <h1 className="logo">Hoopsbot 🏀🤖</h1>
        </Link>
        <CountdownTimer />
      </header> */}

<nav className="navbar navbar-expand-lg navbar-light bg-light mb-5">
  <Link className="navbar-brand" to="/">Hoopsbot 🏀🤖</Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarNav">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item">
        <Link to="/BasketballAnalytics" className="nav-link">📊 Basketball Sports Analytics</Link>
      </li>
      <li className="nav-item">
        <Link to="/calendar" className="nav-link">🗓️ 2024-25 Basketball Schedules</Link>
      </li>
      <li className="nav-item">
        <Link to="/wnba" className="nav-link">🏀 Basketball Analysis</Link>
      </li>
      <li className="nav-item">
        <Link to="/nba-odds" className="nav-link">🤑 NBA Basketball Odds</Link>
      </li>
      <li className="nav-item">
        <Link to="/ku-basketball-schedule" className="nav-link">🗓️ KU Basketball Schedule</Link>
      </li>
    </ul>
    {/* Login/Logout button */}
    <div className="navbar-nav ml-auto">
      {user ? (
        <button className="nav-link btn btn-link" onClick={() => netlifyIdentity.logout()}>Logout</button>
      ) : (
        <button className="nav-link btn btn-link" onClick={handleLoginSignup}>Login / Signup</button>
      )}
    </div>
    {/* Uncomment this section when you want to enable search */}
    {/*
    <form className="form-inline my-2 my-lg-0">
      <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
      <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form>
    */}
  </div>
</nav>

      <Elements stripe={stripePromise}>
        <Routes>
          {posts.map((post) => (
            <Route path={post.fields.postUrl} key={post.sys.id} element={<PostDetails id={post.sys.id} />} />
          ))}
          <Route path="/checkout" element={<CheckoutForm />} />
          <Route path="/subscription" element={<SubscriptionForm />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/wnba" element={<WNBA />} />
          <Route path="/nba" element={<NBA />} />
          <Route path="/ncaa" element={<NCAA />} />
          <Route path="/BasketballAnalytics" element={<BasketballAnalytics />} />
          <Route path="/odds" element={<OddsPage />} />
          <Route path="/nba-odds" element={<NBAOdds />} />
          <Route path="/author/:authorId" element={<AuthorPosts />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/welcome-subscriber" element={<WelcomeSubscriber />} />
          <Route path="/identity" element={<Identity />} />
          <Route path="/ku-basketball-schedule" element={<KUBasketballSchedule />} />
          <Route path="/database" element={<DatabasePage />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/color/:name" component={ColorPage} /> {/* New route for ColorPage */}
          <Route path="/" element={
            <>
              <main>
                <h1>Basketball Analytics</h1>
                <p>Hoopsbot is the home of all things basketball analytics for NBA, NCAA, and WNBA basketball. Whether you are a data head, fan, sharp, or just looking for a new angle on basketball we are here for you.</p>
              

              {/* <CountdownTimer /> */}
              {/* {user ? (
                  <>
                    <span>{`Hi, ${user.user_metadata.full_name}!`}</span>
                    <a href="/account">My Account</a>
                    <h5>Logged in as: {user.user_metadata.full_name ? user.user_metadata.full_name : 'Guest'}</h5>
                  </>
                ) : (
                  <>
                    <a onClick={handleLoginSignup}>Login / Signup</a>
                    <h5>Logged in as: Guest</h5>
                  </>
                )} */}

                <div className="row">
                  {posts.map(post => (
                    <div key={post.sys.id} className="col-md-4 mb-5">
                      <div className="card">
                        <Link to={post.fields.postUrl}>
                          {post.fields.postImage &&
                            <img
                              src={post.fields.postImage.fields.file.url}
                              alt={post.fields.postImage.fields.description || ''}
                              className="card-img-top"
                            />
                          }
                        </Link>
                        <div className="card-body">
                          <Link to={post.fields.postUrl} className="text-decoration-none text-dark">
                            <h5 className="card-title">{post.fields.title}</h5>
                          </Link>
                          <p>{truncateWords(richTextToPlainText(post.fields.body), 25)}
                          <br /><Link to={post.fields.postUrl} className="card-link">> More</Link></p>
                          
                          <p>{formatDate(post.fields.published)}</p>
                          {post.fields.blogAuthor && (
                            <div className="d-flex align-items-center mt-2">
                              <img
                                src={post.fields.blogAuthor.fields.authorImage.fields.file.url}
                                alt={post.fields.blogAuthor.fields.name}
                                style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                              />
                              <span className="ml-2">
                                <Link to={`/author/${post.fields.blogAuthor.sys.id}`}>
                                  {post.fields.blogAuthor.fields.name}
                                </Link>
                              </span>
                            </div>
                          )}
                          
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </main>
            </>
          } />
        </Routes>
      </Elements>
{/* Footer */}
<footer className="text-center text-lg-start bg-white text-muted">
  {/* Section: Social media */}
  <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
    {/* Left */}
    {/* <div className="me-5 d-none d-lg-block">
      <span>Get connected with us on social networks:</span>
    </div> */}
    {/* Right */}
    {/* <div>
      <a href="#" className="me-4 link-secondary">
        <i className="fab fa-facebook-f"></i>
      </a>
      <a href="#" className="me-4 link-secondary">
        <i className="fab fa-twitter"></i>
      </a>
      <a href="#" className="me-4 link-secondary">
        <i className="fab fa-google"></i>
      </a>
      <a href="#" className="me-4 link-secondary">
        <i className="fab fa-instagram"></i>
      </a>
      <a href="#" className="me-4 link-secondary">
      <i class="fa-brands fa-linkedin-in"></i>
        <i className="fab fa-linkedin"></i>
      </a>
      <a href="#" className="me-4 link-secondary">
        <i className="fab fa-github"></i>
      </a>
    </div> */}
  </section>
  {/* Section: Links */}
  <section className="">
    <div className="container text-center text-md-start mt-5">
      {/* Grid row */}
      <div className="row mt-3">
        {/* Grid column */}
        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          {/* Content */}
          <h6 className="text-uppercase fw-bold mb-4">
            <i className="fas fa-gem me-3 text-secondary"></i>Hoopsbot
          </h6>
          <p>
          <Link to="/identity" className="text-reset">Account</Link>
          </p>
          <p>
          <Link to="/about" className="text-reset">About</Link>
          </p>
          <p>
            NBA, NCAA, and WNBA basketball stats explained. Unlock the power of basketball analytics. 
          </p>
        </div>
        {/* Grid column */}
        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          {/* Links */}
          <h6 className="text-uppercase fw-bold mb-4">
            Basketball League Analysis
          </h6>
          <p>
          <Link to="/nba" className="text-reset">NBA Basketball Analysis</Link>
          </p>
          <p>
          <Link to="/ncaa" className="text-reset">NCAA Basketball Analysis</Link>
          </p>
          <p>
          <Link to="/wnba" className="text-reset">WNBA Basketball Analysis</Link>
          </p>
        <p>
        <Link to="/teams" className="nav-link">Teams</Link>
        </p>
        </div>
        {/* Grid column */}
        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
        
          <h6 className="text-uppercase fw-bold mb-4">
            Basketball Odds
          </h6>
          <p>
          <Link to="/odds" className="text-reset">WNBA Basketball Odds</Link>
          </p>
          {/* 
          <p>
            <a href="#" className="text-reset">Settings</a>
          </p>
          <p>
            <a href="#" className="text-reset">Orders</a>
          </p>
          <p>
            <a href="#" className="text-reset">Help</a>
          </p> */}
        </div>
        {/* Grid column */}
        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          {/* Links */}
          <h6 className="text-uppercase fw-bold mb-4">Let's Connect</h6>
          <p>🌆 San Diego, CA</p>
          <p>📧 hi@hoopsbot.com
          </p>
          <p>❌ @hoopsbot</p>
        </div>
      </div>
    </div>
  </section>
  {/* Copyright */}
  <div className="text-center p-4" style={{backgroundColor: 'rgba(0, 0, 0, 0.025)'}}>
    © 2024 Hoopsbot. All rights reserved.
  </div>
</footer>





    </div>
  );
}



function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

export default App;
