import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';

function Teams() {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    const { data, error } = await supabase
      .from('teams')
      .select('team_name'); // Updated to match the correct column name

    if (error) {
      console.error('Error fetching teams:', error);
    } else {
      console.log('Fetched teams:', data); // Log the fetched data for debugging
      setTeams(data);
    }
  };

  return (
    <div>
      <h1>Teams</h1>
      <ul>
        {teams.length > 0 ? (
          teams.map((team) => (
            <li key={team.team_name}>{team.team_name}</li>
          ))
        ) : (
          <p>No teams available.</p>
        )}
      </ul>
    </div>
  );
}

export default Teams;
