import React from 'react';
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <div>
      <Helmet>
        <title>Contact - Hoopsbot Blog 🏀🤖</title>
        <meta name="description" content="Contact Hoopsbot" />
        <link rel="canonical" href="https://hoopsbot.com/contact" />

        
      </Helmet>

      <div class="contact-container">
    <h2>Get in Touch!</h2>
    <p>
        At <strong>Hoopsbot</strong>, we're all about the game and its vibrant community. We value our readers, their feedback, and their insights.
    </p>

    <p>
        Looking to connect? The best way to reach out to us is on Twitter. Whether you have a question, a suggestion, or simply want to chat about the latest in basketball, we're all ears.
    </p>

    <p>
        🏀 <strong>Follow and DM us:</strong> <a href="https://twitter.com/thehoopsbot" target="_blank" rel="noopener noreferrer">@thehoopsbot</a>
    </p>

    <p>
        We look forward to hearing from you and discussing all things basketball. Keep shooting those hoops!
    </p>
  

</div>



    </div>
  );
}

export default Contact;
