import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

const cardStyle = {
  style: {
    base: {
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
  },
};

function CheckoutForm() {
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // <- Here's the change

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    // Fetch the payment intent client secret from Netlify function
    fetch('/.netlify/functions/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: 1099 }),
    })
    .then((response) => response.json())
    .then((data) => {
      const clientSecret = data.clientSecret;

      return stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: { email },
        },
      });
    })
    .then((result) => {
      if (result.error) {
        console.error(result.error);
      } else {
        console.log('[PaymentMethod]', result.paymentIntent);
        
        // Redirect to welcome page
        navigate('/welcome');
      }
    });
  };

  return (
    <div className="container">
      <h1 className="text-center mt-5">Donate to Hoopsbot</h1>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
          />
        </div>
        <div className="form-group">
          <label>Card Number</label>
          <CardNumberElement options={cardStyle} className="form-control" />
        </div>
        <div className="form-group">
          <label>Expiration Date</label>
          <CardExpiryElement options={cardStyle} className="form-control" />
        </div>
        <div className="form-group">
          <label>CVC</label>
          <CardCvcElement options={cardStyle} className="form-control" />
        </div>
        <button type="submit" className="btn btn-primary" disabled={!stripe}>
          Donate
        </button>
      </form>
    </div>
  );
}

export default CheckoutForm;
