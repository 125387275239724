// WelcomeSubscriber.js
import React from 'react';

const WelcomeSubscriber = () => {
  return (
    <div className="container">
      <h1>Welcome to Hoopsbot!</h1>
      <p>You are subscribed. You're awesome!</p>
      // Add more copy here as needed.
    </div>
  );
};

export default WelcomeSubscriber;
