import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const DatabasePage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/.netlify/functions/fetch-data');
        if (!response.ok) {
          throw new Error('Data could not be fetched');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>Database Data</h1>
      <ul>
        {data.map((item, index) => (
          <li key={index}>
          <Link to={`/color/${item.name}`}>{item.name}</Link> - {item.favorite_color}
        </li>
        ))}
      </ul>
    </div>
  );
};

export default DatabasePage;
