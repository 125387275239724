import React from 'react';
import './App.css';
import { Helmet } from 'react-helmet';

function Calendar() {
  return (
    <div>
<Helmet>
    <title>NBA, NCAA, WNBA Basketball Schedules ~ Hoopsbot 🏀🤖</title>
    <meta name="description" content="The Ultimate Basketball Schedule Guide 2023-2024. Get insights into NCAA men's and women's basketball schedules, NBA, and more!" />
    <link rel="canonical" href="https://hoopsbot.com/calendar" />
    <script type="application/ld+json">
    {`
        {
          "@context": "http://schema.org",
          "@type": "Event",
          "name": "2023-24 College Basketball Season Start",
          "startDate": "2023-10-15T00:00:00Z", 
          "location": {
            "@type": "Place",
            "name": "Various Colleges & Universities",
            "address": {
              "@type": "PostalAddress",
              "addressCountry": "US"
            }
          },
          "description": "The beginning of the 2023-24 college basketball season.",
          "image": "https://gifcdn.com/dhp60ojab9i68qjichi.gif",
          "organizer": {
            "@type": "Organization",
            "name": "NCAA"
          }
        }
    `}
    </script>
</Helmet>


      <div className="hero-image-container">
        <img src={process.env.PUBLIC_URL + '/basketball_schedule.png'} alt="2023-24 NBA, NCAA, WNBA Basketball Schedule" className="hero-image" />
        </div>

      <h1>The Ultimate NCAA and NBA Basketball Schedule Guide 2023-2024</h1>
      
      <div class="container">
      <h2>NCAA Season Tournaments Schedule at a Glance</h2>

    <h3>2023-24 NCAA Men's Basketball: New Rules, New Dynamics</h3>
    <p>
        The air is charged with anticipation as the 2023-24 NCAA Division I men's basketball season is set to tip off. From the opening games on November 6 to the climax at the State Farm Stadium in Glendale with the championship game on April 8, 2024, schools are gearing up for a season of thrills, high stakes, and the unexpected.
    </p>
    <h3>Game-Changing Rules on the Horizon</h3>
    <p>
        The NCAA isn't just about maintaining tradition; it's about refining the game. On May 5, the NCAA Basketball Rules Committee unveiled a list of rule changes set to take effect this season, injecting new dynamics into the sport we love. Here's the rundown:
    </p>
    <ul>
        <li>Defenders can now draw a charge near the basket only if they're in position before the shooter plants his foot for a jump.</li>
        <li>Teams can feed prerecorded or live video to the bench during games, a rule that's moved from experimental to permanent status.</li>
        <li>Referees can now double-check basket interference or goaltending calls during the next media timeout, ensuring no points are left hanging.</li>
        <li>Non-student personnel can act as peacekeepers during on-court altercations, keeping the heat of the moment from boiling over.</li>
        <li>Coaches risk a timeout if they unsuccessfully challenge an out-of-bounds play in the final two minutes.</li>
        <li>The shot clock gets a reset to 20 seconds after an offensive rebound, keeping the game fast-paced and heart rates up.</li>
        <li>Players wrongfully penalized because of an opponent's flagrant foul? Justice will be served with the foul rescinded.</li>
        <li>A timeout can now be called by a player in mid-air possession, bringing new strategy to those nail-biting final seconds.</li>
        <li>Three strikes and you’re out! Players will be disqualified after committing three flagrant 1 fouls, regardless of total fouls.</li>
        <li>Backboards will get an upgrade with red and amber lights, making shot clock violations clearer and games fairer.</li>
        <li>Religious headwear that's game-safe no longer requires a waiver, respecting players' beliefs on the court.</li>
        <li>Jersey numbers are now as unrestricted as the players, with all digits from 0–99 in play.</li>
        <li>Conferences can continue experimenting with media timeouts in the second half, potentially reducing game interruptions.</li>
        <li>Keep an eye on the 2024 NIT, where the free-throw lane might just get a stretch to 16 feet, pending board approval.</li>
    </ul>
    <p>
        With these changes, the NCAA continues to balance tradition with innovation. As teams hit the court this season, it's not just about who's got the best game. It's about who's best adapted to the new rules of the game.
    </p>


    <h2>NCAA Early Season Tournaments Schedule</h2>
    <table class="table">
        <thead class="thead-dark">
            <tr>
                <th>Name</th>
                <th>Dates</th>
                <th>Location</th>
                <th>Teams</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Asheville Championship</td>
                <td>Nov 10–12</td>
                <td>Harrah's Cherokee Center, Asheville, NC</td>
                <td>4</td>
            </tr>
            <tr>
                <td>Legends Classic</td>
                <td>Nov 16–17</td>
                <td>Barclays Center, Brooklyn, NY</td>
                <td>4</td>
            </tr>
           <tr>
    <td>Legends Classic</td>
    <td>Nov 16–17</td>
    <td>Barclays Center, Brooklyn, NY</td>
    <td>4</td>
</tr>
<tr>
    <td>Bahamas Championship</td>
    <td>Nov 17–19</td>
    <td>Baha Mar Convention Center, Nassau, Bahamas</td>
    <td>4</td>
</tr>
<tr>
    <td>Charleston Classic</td>
    <td>Nov 17–19</td>
    <td>TD Arena, Charleston, SC</td>
    <td>8</td>
</tr>
<tr>
    <td>Myrtle Beach Invitational</td>
    <td>Nov 17–19</td>
    <td>HTC Center, Conway, SC</td>
    <td>8</td>
</tr>
<tr>
    <td>Nassau Championship</td>
    <td>Nov 17–19</td>
    <td>Baha Mar Convention Center, Nassau, Bahamas</td>
    <td>8</td>
</tr>
<tr>
    <td>Continental Tire Main Event</td>
    <td>Nov 17–19</td>
    <td>T-Mobile Arena, Las Vegas, NV</td>
    <td>4</td>
</tr>
<tr>
    <td>Hall of Fame Tip Off</td>
    <td>Nov 18 & 19</td>
    <td>Mohegan Sun Arena, Uncasville, CT</td>
    <td>4</td>
</tr>
<tr>
    <td>Paradise Jam Tournament</td>
    <td>Nov 18–21</td>
    <td>Sports and Fitness Center, Saint Thomas, VI</td>
    <td>8</td>
</tr>
<tr>
    <td>Jacksonville Classic</td>
    <td>Nov 19 & 20</td>
    <td>Flagler Gymnasium, St. Augustine, FL</td>
    <td>8</td>
</tr>
<tr>
    <td>Cayman Islands Classic</td>
    <td>Nov 19–21</td>
    <td>John Gray Gymnasium, George Town, Grand Cayman, CYM</td>
    <td>8</td>
</tr>
<tr>
    <td>Sunshine Slam</td>
    <td>Nov 20 & 21</td>
    <td>Ocean Center, Daytona Beach, FL</td>
    <td>8</td>
</tr>
<tr>
    <td>Fort Myers Tip-Off</td>
    <td>Nov 20–22</td>
    <td>Suncoast Credit Union Arena, Fort Myers, FL</td>
    <td>8</td>
</tr>
<tr>
    <td>Gulf Coast Showcase</td>
    <td>Nov 20–22</td>
    <td>Hertz Arena, Estero, FL</td>
    <td>8</td>
</tr>
<tr>
    <td>Jamaica Classic</td>
    <td>Nov 20–22</td>
    <td>Montego Bay Convention Centre, Montego Bay, JA</td>
    <td>8</td>
</tr>
<tr>
    <td>Maui Invitational</td>
    <td>Nov 20–22</td>
    <td>Stan Sheriff Center, Honolulu, HI</td>
    <td>8</td>
</tr>
<tr>
    <td>SoCal Challenge</td>
    <td>Nov 20–22</td>
    <td>The Pavilion at JSerra, San Juan, CA</td>
    <td>8</td>
</tr>
<tr>
    <td>Cancún Challenge</td>
    <td>Nov 21 & 22</td>
    <td>Hard Rock Hotel - Riviera Maya, Cancún, MX</td>
    <td>8</td>
</tr>
<tr>
    <td>Empire Classic</td>
    <td>Nov 21 & 22</td>
    <td>Barclays Center, Brooklyn, NY</td>
    <td>4</td>
</tr>
<tr>
    <td>Battle 4 Atlantis</td>
    <td>Nov 22–24</td>
    <td>Imperial Arena, Paradise Island, Nassau, Bahamas</td>
    <td>8</td>
</tr>
<tr>
    <td>Hall of Fame Classic</td>
    <td>Nov 22 & 23</td>
    <td>T-Mobile Center, Kansas City, MO</td>
    <td>4</td>
</tr>
<tr>
    <td>NIT Season Tip-Off</td>
    <td>Nov 22 & 24</td>
    <td>Barclays Center, Brooklyn, NY</td>
    <td>4</td>
</tr>
<tr>
    <td>Rady Children’s Invitational</td>
    <td>Nov 23 & 24</td>
    <td>LionTree Arena, San Diego, CA</td>
    <td>4</td>
</tr>
<tr>
    <td>Wooden Legacy</td>
    <td>Nov 23 & 24</td>
    <td>Anaheim Convention Center, Anaheim, CA</td>
    <td>4</td>
</tr>
<tr>
    <td>ESPN Events Invitational</td>
    <td>Nov 23, 24, & 26</td>
    <td>State Farm Field House, Lake Buena Vista, FL</td>
    <td>8</td>
</tr>
<tr>
    <td>Emerald Coast Classic</td>
    <td>Nov 24 & 25</td>
    <td>The Arena at NFSC, Niceville, FL</td>
    <td>4</td>
</tr>
<tr>
    <td>Phil Knight Invitational</td>
    <td>Nov 24-27</td>
    <td>Chiles Center, Moda Center, and Veterans Memorial Coliseum, Portland, OR</td>
    <td>8</td>
</tr>
<tr>
    <td>Phil Knight Legacy</td>
    <td>Nov 24-27</td>
    <td>Chiles Center, Moda Center, and Veterans Memorial Coliseum, Portland, OR</td>
    <td>8</td>
</tr>
<tr>
    <td>Las Vegas Invitational</td>
    <td>Dec 2 & 3</td>
    <td>Orleans Arena, Las Vegas Valley, NV</td>
    <td>4</td>
</tr>
<tr>
    <td>Diamond Head Classic</td>
    <td>Dec 22-25</td>
    <td>Stan Sheriff Center, Honolulu, HI</td>
    <td>8</td>
</tr>
        </tbody>
    </table>
</div>
      
    </div>
  );
}

export default Calendar;
