import React from 'react';
import { Helmet } from 'react-helmet';



function KUBasketballSchedule() {
  return (
    <div>
      <Helmet>
        <title>Kansas Basketball Schedule 2023-24 - Hoopsbot Blog 🏀🤖</title>
        <meta name="description" content="View the Kansas men's basketball 2023-24 schedule." />
      </Helmet>

    
      <h2>This is permissoned content for subscribers only</h2>
      <h2>This is not permissioned content. Link to SignUp</h2>
      <h2 className="mt-5">Kansas Betting Record 2023-24</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Team</th>
            <th>Conference</th>
            <th>Record</th>
            <th>SQ Record</th>
            <th>Win%</th>
            <th>SQ Win%</th>
            <th>Over Win %</th>
            <th>Under Win %</th>
            <th>Favorite Win %</th>
            <th>Underdog Win%</th>
            <th>ML Record</th>
            <th>SQ ML Record</th>
            <th>ATS Record</th>
            <th>SQ ATS Record</th>
            <th>OU Record</th>
            <th>SQ OU Record</th>
            <th>ATS Value</th>
            <th>O/U Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Kansas</td>
            <td>Big 12</td>
            <td>28 - 8</td>
            <td>25 - 11</td>
            <td>77%</td>
            <td>69%</td>
            <td>47%</td>
            <td>52%</td>
            <td>85%</td>
            <td>66%</td>
            <td>28-8</td>
            <td>21-15</td>
            <td>17-19-0</td>
            <td>12-24-0</td>
            <td>17-19-0</td>
            <td>19-17-0</td>
            <td>(-5 ATS val)</td>
            <td>(2 Over val)</td>
          </tr>
        </tbody>
      </table>

      <h2 className="mt-5">Last Year's Results Against 2023-24 Opponents</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Opponent</th>
            <th>Score</th>
            <th>SQ Score</th>
            <th>Closing Spread</th>
            <th>Spread Result</th>
            <th>Closing Total</th>
            <th>Total Result</th>
          </tr>
        </thead>
        <tbody>
    <tr>
        <td>Sat, 17 Dec</td>
        <td>Indiana</td>
        <td>W, 84-62</td>
        <td>SQ W, 78- 68</td>
        <td>-5</td>
        <td>Win by 17</td>
        <td>142.5</td>
        <td>Over by 3.5</td>
    </tr>
    <tr>
        <td>Sat, 10 Dec</td>
        <td>Missouri</td>
        <td>W, 95-67</td>
        <td>SQ W, 83- 75</td>
        <td>-3.5</td>
        <td>Win by 24.5</td>
        <td>155</td>
        <td>Over by 7</td>
    </tr>
    <tr>
        <td>Thu, 1 Dec</td>
        <td>Seton Hall</td>
        <td>W, 91-65</td>
        <td>SQ W, 76- 69</td>
        <td>-11</td>
        <td>Win by 15</td>
        <td>137.5</td>
        <td>Over by 18.5</td>
    </tr>
    <tr>
        <td>Sat, 28 Jan</td>
        <td>Kentucky</td>
        <td>W, 77-68</td>
        <td>SQ L, 67- 68</td>
        <td>3</td>
        <td>Win by 12</td>
        <td>140.5</td>
        <td>Over by 4.5</td>
    </tr>
    <tr>
        <td>Mon, 23 Jan</td>
        <td>Baylor</td>
        <td>L, 69-75</td>
        <td>SQ L, 59- 79</td>
        <td>2</td>
        <td>Loss by 4</td>
        <td>149.5</td>
        <td>Under by 5.5</td>
    </tr>
    <tr>
        <td>Sat, 7 Jan</td>
        <td>West Virginia</td>
        <td>W, 76-62</td>
        <td>SQ L, 69- 74</td>
        <td>-2</td>
        <td>Win by 12</td>
        <td>146</td>
        <td>Under by 8</td>
    </tr>
    <tr>
        <td>Tue, 3 Jan</td>
        <td>Texas Tech</td>
        <td>W, 75-72</td>
        <td>SQ W, 66- 64</td>
        <td>0</td>
        <td>Win by 3</td>
        <td>140.5</td>
        <td>Over by 6.5</td>
    </tr>
</tbody>
      </table>
      
      <h2>Kansas Men's Basketball 2023-24 Schedule</h2>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Opponent</th>
            <th>Location</th>
            <th>Result/Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Aug 3</td>
            <td>11:00 AM CT</td>
            <td>Puerto Rico Select Team (EXH)</td>
            <td>Bayamón, Puerto Rico</td>
            <td>W 106-71</td>
          </tr>
          <tr>
            <td>Aug 5</td>
            <td>4:00 PM CT</td>
            <td>Bahamian National Team (EXH)</td>
            <td>Bayamón, Puerto Rico</td>
            <td>W 92-87</td>
          </tr>
          <tr>
            <td>Aug 7</td>
            <td>11:00 AM CT</td>
            <td>Bahamian National Team (EXH)</td>
            <td>Bayamón, Puerto Rico</td>
            <td>L 81-87</td>
          </tr>
          <tr>
            <td>Oct 6</td>
            <td>TBA</td>
            <td>Late Night in the Phog</td>
            <td>Lawrence, Kan.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Oct 29</td>
            <td>5:00 PM CT</td>
            <td>Illinois (EXH)</td>
            <td>Champaign, Ill.</td>
            <td>5:00 PM CT</td>
          </tr>
          <tr>
            <td>Nov 1</td>
            <td>TBA</td>
            <td>Fort Hays State (EXH)</td>
            <td>Lawrence, Kan.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Nov 6</td>
            <td>TBA</td>
            <td>North Carolina Central</td>
            <td>Lawrence, Kan.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Nov 10</td>
            <td>TBA</td>
            <td>Manhattan</td>
            <td>Lawrence, Kan.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Nov 14</td>
            <td>TBA</td>
            <td>Kentucky</td>
            <td>Chicago, Ill.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Nov 20</td>
            <td>8:00 PM CT</td>
            <td>Chaminade</td>
            <td>Honolulu, Hawaii</td>
            <td>8:00 PM CT</td>
          </tr>
          <tr>
            <td>Nov 21</td>
            <td>TBA</td>
            <td>Second Round</td>
            <td>Honolulu, Hawaii</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Nov 22</td>
            <td>TBA</td>
            <td>Third Round</td>
            <td>Honolulu, Hawaii</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Nov 28</td>
            <td>TBA</td>
            <td>Eastern Illinois</td>
            <td>Lawrence, Kan.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Dec 1</td>
            <td>TBA</td>
            <td>UConn</td>
            <td>Lawrence, Kan.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Dec 5</td>
            <td>TBA</td>
            <td>Kansas City</td>
            <td>Lawrence, Kan.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Dec 9</td>
            <td>TBA</td>
            <td>Missouri</td>
            <td>Lawrence, Kan.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Dec 16</td>
            <td>TBA</td>
            <td>Indiana</td>
            <td>Bloomington, Ind.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Dec 22</td>
            <td>TBA</td>
            <td>Yale</td>
            <td>Lawrence, Kan.</td>
            <td>TBA</td>
          </tr>
          <tr>
            <td>Dec 30</td>
            <td>TBA</td>
            <td>Wichita State</td>
            <td>Kansas City, Mo. (T-Mobile Center)</td>
            <td>TBA</td>
          </tr>
        </tbody>
      </table>



      <div className="container mt-5">

{/* Aug 3 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Aug 3, 11:00 AM CT</h5>
        <p className="card-text">Kansas vs Puerto Rico Select Team (EXH) - Bayamón, Puerto Rico</p>
        <p className="card-text"><strong>Result:</strong> W 106-71</p>
    </div>
</div>

{/* Aug 5 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Aug 5, 4:00 PM CT</h5>
        <p className="card-text">Kansas vs Bahamian National Team (EXH) - Bayamón, Puerto Rico</p>
        <p className="card-text"><strong>Result:</strong> W 92-87</p>
    </div>
</div>

{/* Aug 7 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Aug 7, 11:00 AM CT</h5>
        <p className="card-text">Kansas vs Bahamian National Team (EXH) - Bayamón, Puerto Rico</p>
        <p className="card-text"><strong>Result:</strong> L 81-87</p>
    </div>
</div>

{/* Oct 6 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Oct 6, TBA</h5>
        <p className="card-text">Late Night in the Phog - Lawrence, Kan.</p>
        <p className="card-text">TBA</p>
    </div>
</div>

{/* Oct 29 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Oct 29, 5:00 PM CT</h5>
        <p className="card-text">Kansas vs Illinois (EXH) - Champaign, Ill.</p>
        <p className="card-text">5:00 PM CT</p>
    </div>
</div>

{/* Nov 1 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Nov 1, TBA</h5>
        <p className="card-text">Kansas vs Fort Hays State (EXH) - Lawrence, Kan.</p>
        <p className="card-text">TBA</p>
    </div>
</div>

{/* Nov 6 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Nov 6, TBA</h5>
        <p className="card-text">Kansas vs North Carolina Central - Lawrence, Kan.</p>
        <p className="card-text">TBA</p>
    </div>
</div>

{/* Nov 10 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Nov 10, TBA</h5>
        <p className="card-text">Kansas vs Manhattan - Lawrence, Kan.</p>
        <p className="card-text">TBA</p>
    </div>
</div>

{/* Nov 14 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Nov 14, TBA</h5>
        <p className="card-text">Kansas vs Kentucky - Chicago, Ill.</p>
        {/* Summarized data from last year */}
        <p className="card-text">Last year, Kansas faced Kentucky and secured a victory with a score of 77-68. Despite the sports quants predicting a loss, Kansas prevailed. They also managed to cover the spread by winning by 12 points and the game went over by 4.5 points.</p>
    </div>
</div>

{/* Nov 20 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Nov 20, 8:00 PM CT</h5>
        <p className="card-text">Kansas vs Chaminade - Honolulu, Hawaii</p>
        <p className="card-text">8:00 PM CT</p>
    </div>
</div>

{/* Nov 21 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Nov 21, TBA</h5>
        <p className="card-text">Second Round - Honolulu, Hawaii</p>
        <p className="card-text">TBA</p>
    </div>
</div>

{/* Nov 22 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Nov 22, TBA</h5>
        <p className="card-text">Third Round - Honolulu, Hawaii</p>
        <p className="card-text">TBA</p>
    </div>
</div>

{/* Nov 28 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Nov 28, TBA</h5>
        <p className="card-text">Kansas vs Eastern Illinois - Lawrence, Kan.</p>
        <p className="card-text">TBA</p>
    </div>
</div>

{/* Dec 1 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Dec 1, TBA</h5>
        <p className="card-text">Kansas vs UConn - Lawrence, Kan.</p>
        <p className="card-text">
        <h4>Playtype Insights for Kansas:</h4>
<ul>
    <li><strong>Finishing at the Rim:</strong> Kansas stands out, ranking 28th in frequency. Their ATS record against weaker defensive teams is 13-14, and UConn's defensive rank in 2022 was a low 355th.</li>
    <li><strong>Post-Up Play:</strong> With a frequency rank of 84th, Kansas went 4-5 ATS against teams ranked 211-280. UConn's defensive ranking for this playtype was 263rd.</li>
    <li><strong>Other Key Plays:</strong> Kansas's cut, off-screen, and catch & shoot 3PT playtypes are also notable. UConn struggled defensively against these, with rankings of 355th, 339th, and 357th respectively.</li>
</ul>

<h4>Playtype Insights for UConn:</h4>
<ul>
    <li><strong>Finishing at the Rim:</strong> UConn shines here, ranking 37th. They went 7-14 ATS against teams weak in rim defense, and Kansas was ranked 290th in this category in 2022.</li>
    <li><strong>Off-Screen Plays:</strong> UConn's frequency rank is 48th. Their ATS record is 2-5 against teams below rank 280, while Kansas stood at 305th.</li>
    <li><strong>Additional Playtypes:</strong> UConn's post-up, cut, and off the dribble 3PT plays are significant. Kansas's defensive ranks against these were 299th, 286th, and 260th respectively.</li>
</ul>

        </p>
    </div>
</div>

{/* Dec 5 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Dec 5, TBA</h5>
        <p className="card-text">Kansas vs Kansas City - Lawrence, Kan.</p>
        <p className="card-text">TBA</p>
    </div>
</div>

{/* Dec 9 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Dec 9, TBA</h5>
        <p className="card-text">Kansas vs Missouri - Lawrence, Kan.</p>
        {/* Summarized data from last year */}
        <p className="card-text">Last year, Kansas faced Missouri and secured a resounding victory with a score of 95-67, exceeding the sports quants' prediction. Kansas also covered the 3.5-point spread with ease, winning by 24.5 points. The game went over the total by 7 points.</p>
    </div>
</div>

{/* Dec 16 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Dec 16, TBA</h5>
        <p className="card-text">Kansas vs Indiana - Bloomington, Ind.</p>
        {/* Summarized data from last year */}
        <p className="card-text">Last year, Kansas secured a dominating win against Indiana with a score of 84-62, comfortably covering the 5-point spread.</p>
    </div>
</div>

{/* Dec 22 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Dec 22, TBA</h5>
        <p className="card-text">Kansas vs Yale - Lawrence, Kan.</p>
        <p className="card-text">TBA</p>
    </div>
</div>

{/* Dec 30 Game Card */}
<div className="card mb-3">
    <div className="card-body">
        <h5 className="card-title">Dec 30, TBA</h5>
        <p className="card-text">Kansas vs Wichita State - Kansas City, Mo. (T-Mobile Center)</p>
        <p className="card-text">TBA</p>
    </div>
</div>

</div>




    </div>
  );
}

export default KUBasketballSchedule;
