import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { Helmet } from 'react-helmet'; // Import Helmet

const NBAOdds = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('/projected_scores.csv').then(response => {
            // Parse CSV data
            Papa.parse(response.data, {
                complete: (result) => {
                    setData(result.data);
                },
                header: true
            });
        });
    }, []);

    const has_odds = true; // Set this to true or false manually

    return (
        <div>
            <Helmet>
                <title>FREE Projected NBA Scores and Odds ~ Hoopsbot</title>
                <meta name="description" content="Get FREE NBA picks for each WNBA game including against the spread, totals, and moneyline powered by deep basketball analysis." />
                <link rel="canonical" href="https://hoopsbot.com/odds" />
            </Helmet>
            <h1>NBA Projected Scores, Odds and Insights</h1>
            <p>Welcome to our NBA Projected Scores page, your premier destination for the latest game projections and odds analysis. With matchups constantly evolving and the stakes forever rising, we aim to keep you ahead of the curve. Our projections, rooted in deep analytics and expert insights, are designed to provide you with an edge, whether you're a seasoned bettor or a passionate fan seeking deeper understanding of the game.</p>
            {has_odds ? (
                <table className="table table-bordered table-hover">
                    <thead className="thead-light">
                        <tr>
                            <th>Date</th>
                            <th>Match</th>
                            <th>Projected Score</th>
                            <th>Winner</th>
                            <th>Wins By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td>{row.Date}</td>
                                <td>{row.Match}</td>
                                <td>{row["Projected Score"]}</td>
                                <td>{row.Winner}</td>
                                <td>{row["Wins By"]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        



            ) : (
                <p>Sorry, there are no upcoming games available.</p>
            )}
        </div>
    );
};

export default NBAOdds;