import React, { useState, useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { Helmet } from 'react-helmet';

function Identity() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Initialize Netlify Identity
    netlifyIdentity.init();
    const user = netlifyIdentity.currentUser();
    setUser(user);

    // Listen for login and logout events
    netlifyIdentity.on('login', handleLogin);
    netlifyIdentity.on('logout', handleLogout);

    // Cleanup
    return () => {
      netlifyIdentity.off('login', handleLogin);
      netlifyIdentity.off('logout', handleLogout);
    };
  }, []);

  const handleLogin = (user) => {
    console.log("User logged in", user);
    setUser(user);
  };

  const handleLogout = () => {
    console.log("User logged out");
    setUser(null);
  };

  const handleLoginSignup = () => {
    netlifyIdentity.open();
  };

  return (
    <div>
      <Helmet>
        <title>Identity - Hoopsbot Blog 🏀🤖</title>
        <meta name="description" content="Hoopsbot Identity" />
        <link rel="canonical" href="https://hoopsbot.com/identity" />
      </Helmet>

      <div className="contact-container">
  <h2>Identity Test</h2>
  <p>Welcome to <strong>Hoopsbot</strong>! Let's dive into some basketball insights.</p>

  {user ? (
    <div>
      <span>{`Hey ${user.user_metadata.full_name}, you're in the zone! 🏀`}</span>
      <span>{`Your Email: ${user.email || 'Not provided'} `}</span><br />
      <span>{`Payment Status: ${user.user_metadata.paid || 'Not paid'} `}</span><br />
      <span>{`Role: ${user.app_metadata && user.app_metadata.roles ? user.app_metadata.roles.join(", ") : 'No roles assigned'} `}</span>
      <p>Now that you're signed in, you can access exclusive content, personalized stats, and much more!</p>
      <p>for example here's today's pick of the day: </p>
      <button onClick={() => netlifyIdentity.logout()}>Logout</button>
    </div>
  ) : (
    <div>
      <p>Sign in to unlock exclusive content and features that will elevate your basketball IQ to the next level.</p>
      <button onClick={handleLoginSignup}>Login / Signup</button>
    </div>
  )}
</div>

    </div>
  );
}

export default Identity;
