import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import client from './Contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Helmet } from 'react-helmet';

function BasketballAnalytics() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const entries = await client.getEntries({
        content_type: 'blog',
        order: '-fields.published',
        'metadata.tags.sys.id[in]': 'basketballAnalytics'
      });
      if (entries.items) setPosts(entries.items);
    };

    fetchPosts();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, file } = node.data.target.fields;
        const imageUrl = file.url;
        return <img src={`https:${imageUrl}`} alt={title ? title['en-US'] : null} className="img-fluid mb-3" />;
      },
    },
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
    },
  };

  return (
    <div>
      <Helmet>
        <title>Basketball Analytics & Basketball Analytics Companies ~ Hoopsbot Blog 🏀🤖</title>
        <meta name="description" content="Explore the world of basketball analytics with our curated blog posts. Discover how top companies are changing the game, one data point at a time." />
        <link rel="canonical" href="https://hoopsbot.com/BasketballAnalytics" />
      </Helmet>

      <h1>Basketball Analytics: Decoding the Game's Data Revolution</h1>
      <p>Welcome to the nexus of basketball and data science. In an era where every dribble, shot, and pass can be quantified, basketball analytics companies are revolutionizing our understanding of the game. Dive into our curated list of blog posts that shine a spotlight on the groundbreaking work of these firms. Whether you're a coach, player, or a fan, get ready to see the court from an entirely new perspective, all thanks to basketball analytics.</p>

      {posts.map(post => (
        <div key={post.sys.id} className="mb-5">
          <Link to={post.fields.postUrl} className="text-decoration-none text-dark">
            {post.fields.postImage &&
              <img
                src={post.fields.postImage.fields.file.url}
                alt={post.fields.postImage.fields.description || ''}
                style={{ maxWidth: '600px' }}
                className="img-fluid mb-3"
              />
            }
            <h3>{post.fields.title}</h3>
          </Link>
          {documentToReactComponents(post.fields.body, options)}
          <p><strong>Published on:</strong> {formatDate(post.fields.published)}</p>
        </div>
      ))}

    </div>
  );
}

export default BasketballAnalytics;
